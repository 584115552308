/* eslint-disable camelcase */
import styled from 'styled-components';
import ft_hero_bg from '../../../images/Hero/ft_hero_bg.png';
import ft_hero_mobile_bg from '../../../images/Hero/ft_hero_mobile_bg.png';

export default styled.section`
  padding-top: 105px;

  &.withTopBar {
    padding-top: 96px;
  }

  & .announcement-bar {
    position: absolute;
    z-index: 1000;
    top: 0;
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    background: linear-gradient(90deg, #fc6a2c 0%, #e91d24 100%);

    .notice {
      letter-spacing: 1.5px;
    }

    & p {
      color: #fff;
      font-weight: 700;
      margin-bottom: 0;

      @media (max-width: 768px) {
        font-size: 14px;
      }
    }

    & .link-register {
      color: #fff;
      border-bottom: 2px solid #fff;
      padding-bottom: 0.1px;
      text-decoration: none;
    }
  }

  @media screen and (max-width: 768px) {
    padding-top: 40px;
  }
  .video-bg {
    position: fixed;
    margin: -105px auto;
    width: 100%;
    video {
      margin: 0 auto;

      @media (max-width: 768px) {
        width: auto;
        margin-left: -650px;
      }
    }
  }
  .image-bg {
    position: absolute;
    margin: -105px auto;
    background-image: linear-gradient(
        to bottom,
        transparent,
        transparent,
        #08080a 95%
      ),
      url(${ft_hero_bg});
    background-repeat: no-repeat;
    width: 100%;
    min-height: 100%;
    background-position: top;
    background-size: 100%;
    @media screen and (max-width: 768px) {
      background-image: url(${ft_hero_mobile_bg});
      background-image: linear-gradient(to bottom, transparent, #080809 64%),
        url(${ft_hero_mobile_bg});
      background-position: top;
    }
  }
  .main-logo {
    @media screen and (max-width: 768px) {
      img {
        max-width: 170px;
      }
    }
  }
  .logo-area {
    margin-top: 32px;
    margin-bottom: 32px;
    @media screen and (max-width: 768px) {
      margin-top: 24px;
      margin-bottom: 24px;
      img {
        max-width: 327px;
      }
    }
  }
  .hero-desc-area {
    margin: 0 auto;
    max-width: 800px;
    font-style: normal;
    font-size: 22.5px;
    line-height: 140%;
    text-align: center;
    color: #fff;
    span {
      color: #fc6a2c;
      font-weight: bold;
    }
    @media screen and (max-width: 768px) {
      font-size: 18px;
      max-width: auto;
    }
  }
  .thank-you-container {
    padding-top: 130px;
    padding-bottom: 130px;
    text-align: center;
    width: 100%;
    z-index: 1000;

    @media (min-width: 1500px) {
      padding-bottom: 220px;
    }

    @media (max-width: 768px) {
      padding-top: 55px;
      padding-bottom: 55px;
    }
  }

  .thankyou-title {
    color: #fff;
    font-weight: 700;
    font-size: 48px;

    @media (max-width: 768px) {
      font-size: 32px;
      padding: 0 16px;
    }

    .thank-you-text {
      color: rgb(252, 106, 44);
    }
  }

  .thankyou-subtext {
    color: #fff;
    font-size: 22px;
    max-width: 747px;
    margin: 0 auto;

    @media (max-width: 768px) {
      font-size: 18px;
      padding: 0 16px;
    }
  }

  .learn-more {
    margin-top: 32px;
    background-color: #fc6a2c;
    border-color: #fc6a2c;

    &:hover {
      background-color: #fc6a2c !important;
    }
  }
`;
